import styled, { css } from "styled-components";
import { switchProp, ifProp, prop } from "styled-tools";

import media from "styles/media";
import variables from "styles/variables";

export const StyledText = styled.div`
  color: ${switchProp("color", {
    inherit: "inherit",
    primary: variables.colors.primary,
    secondary: variables.colors.secondary,
    light: variables.colors.light,
  })};

  display: ${ifProp("inline", "inline-block")};
  text-align: ${prop("align")};

  ${switchProp("variant", {
    h1: css`
      margin: 0;
      padding: 0;
      font-weight: normal;
      font-size: 22px;
      ${media.sm`font-size: 40px;`}
    `,
    h2: css`
      margin: 20px 0;
      font-size: 28px;
    `,
    h3: css`
      margin: 10px 0;
    `,
    p: css`
      font-size: 14px;
      line-height: 1.4;
    `,
  })}
`;
