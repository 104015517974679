import React from "react";
import PropTypes from "prop-types";
import GatsbyLink from "gatsby-link";
import { OutboundLink } from "gatsby-plugin-google-analytics";

import { getPageFromPath } from "utils/common";

const getProps = props => {
  const href = getPageFromPath(props.href);
  const pathname = getPageFromPath(props.location.pathname);
  if (pathname === href) {
    return { className: "active" };
  }
};

const Link = ({ children, local, href, ...props }) => {
  return local ? (
    <GatsbyLink activeClassName="active" getProps={getProps} to={href} {...props}>
      {children}
    </GatsbyLink>
  ) : (
    <OutboundLink target="_blank" rel="noopener noreferrer" href={href} {...props}>
      {children}
    </OutboundLink>
  );
};

Link.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  local: PropTypes.bool,
};

Link.defaultProps = {
  local: true,
};

export default Link;
