import styled from "styled-components";
import { switchProp } from "styled-tools";

export const IconWrapper = styled.div`
  font-family: Icons;
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: 0.8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  font-size: 120%;

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const IconContent = styled.div`
  &:before {
    content: ${switchProp("icon", {
      twitter: "'\f099'",
      menu: "'\f0c9'",
      linkedin: "'\f0e1'",
      github: "'\f113'",
      stackoverflow: "'\f16c'",
      codepen: "'\f32c'",
    })};
  }
`;
