import { css } from "styled-components";
import variables from "./variables";

const sizes = {
  xs: `(min-width: ${variables.media.xs}px)`,
  sm: `(min-width: ${variables.media.sm}px)`,
  md: `(min-width: ${variables.media.md}px)`,
  lg: `(min-width: ${variables.media.lg}px)`,
  xl: `(min-width: ${variables.media.xl}px)`,
};

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media ${sizes[label]} {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

export default media;
