import React from "react";
import PropTypes from "prop-types";

import { FontDelayerContext } from "components/utils/FontDelayer";

import { Delayer } from "./styles";

const SSRAnimation = ({ children }) => (
  <FontDelayerContext.Consumer>
    {({ loaded }) => (
      <Delayer initialPose="invisible" pose={loaded ? "visible" : "invisible"}>
        {children}
      </Delayer>
    )}
  </FontDelayerContext.Consumer>
);

SSRAnimation.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SSRAnimation;
