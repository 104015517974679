import posed from "react-pose";
import styled from "styled-components";
import { ifProp } from "styled-tools";

import variables from "styles/variables";

export const Header = styled.header`
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1;
  position: absolute;
`;

const main = styled.main`
  width: 100vw;
  height: 100%;
  position: relative;
  padding-top: ${ifProp("small", variables.fullHeader, 125)}px;
`;

export const Main = posed(main)({
  enter: { opacity: 1, beforeChildren: true },
  exit: { opacity: 0 },
});
