import posed from "react-pose";
import styled from "styled-components";

import media from "styles/media";
import variables from "styles/variables";

import { paths } from "./utils";

export const Path = posed.path(paths);
export const Svg = styled.svg`
  width: 100%;
  height: 50px;
  display: block;
  fill: ${variables.colors.secondary};
  ${media.md`height: 75px;`};
`;
