import { Component } from "react";
import PropTypes from "prop-types";

class Toggle extends Component {
  state = { toggled: this.props.value };

  toggle = () => this.setState({ toggled: !this.state.toggled });

  render() {
    return this.props.children({ open: this.state.toggled, toggle: this.toggle });
  }
}

Toggle.propTypes = {
  value: PropTypes.bool,
  children: PropTypes.func.isRequired,
};

export default Toggle;
