module.exports = [{
      plugin: require('/home/guskis/work/node/GuskiS.github.io/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Raleway"]}},
    },{
      plugin: require('/home/guskis/work/node/GuskiS.github.io/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-133174158-1"},
    },{
      plugin: require('/home/guskis/work/node/GuskiS.github.io/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
