import React from "react";
import PropTypes from "prop-types";

import SSRAnimation from "components/atoms/SSRAnimation";

import { IconWrapper, IconContent } from "./styles";

const Icon = ({ children, ...props }) => (
  <IconWrapper {...props}>
    <SSRAnimation>
      <IconContent icon={children} />
    </SSRAnimation>
  </IconWrapper>
);

Icon.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Icon;
