import posed from "react-pose";
import styled from "styled-components";

import media from "styles/media";
import variables from "styles/variables";

const direction = { originX: "right", originY: "top" };
const dropdown = posed.div({
  open: { scale: 1, ...direction },
  closed: { scale: 0, ...direction },
});

export const Nav = styled.nav`
  height: ${variables.header}px;
  color: ${variables.colors.light};
  background-color: ${variables.colors.dark};
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

export const Dropdown = styled(dropdown)`
  width: 100%;
  display: flex;
  flex-direction: column;

  left: 0;
  top: ${variables.fullHeader}px;
  z-index: 100;
  position: absolute;
  background-color: ${variables.colors.dark};
`;

export const Wrapper = styled.div`
  width: 100%;
  height: ${variables.header}px;
  padding: 0 20px;
  font-size: 18px;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
`;

export const MobileView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.md`display: none;`};
`;

export const DesktopView = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;
  ${media.md`display: flex;`};
`;
