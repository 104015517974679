import React from "react";
import PropTypes from "prop-types";

import Icon from "components/atoms/Icon";
import Link from "components/atoms/Link";
import Text from "components/atoms/Text";

import { Wrapper } from "./styles";

export const Main = ({ items, onClick }) => {
  return items.map(({ link, title, ...props }) => (
    <Link key={title} href={link} onClick={onClick} {...props}>
      <Wrapper>
        <Text>{title}</Text>
      </Wrapper>
    </Link>
  ));
};

Main.propTypes = {
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func,
};

export const Social = ({ items }) => (
  <Wrapper>
    {items.map(({ link, icon, ...props }) => (
      <Link key={icon} href={link} local={false} {...props}>
        <Icon>{icon}</Icon>
      </Link>
    ))}
  </Wrapper>
);

Social.propTypes = {
  items: PropTypes.array.isRequired,
};
