export const NOT_FOUND = { link: "/404/", title: "Page not found" };

export const ITEMS_MAIN = [
  { link: "/", title: "Home" },
  { link: "/about/", title: "About" },
  { link: "/blog/", title: "Blog" },
  { link: "/contact/", title: "Contact" },
  { link: "https://edvards.lazdans.lv/CV.pdf", title: "Résumé", local: false },
];

export const ITEMS_SOCIAL = [
  { link: "https://github.com/GuskiS", icon: "github", title: "GitHub" },
  { link: "https://www.linkedin.com/in/guskis/", icon: "linkedin", title: "LinkedIn" },
  { link: "https://twitter.com/TehGuskis", icon: "twitter", title: "Twitter" },
  { link: "https://codepen.io/guskis/", icon: "codepen", title: "CodePen" },
  { link: "https://stackoverflow.com/users/4008389/guskis", icon: "stackoverflow", title: "Stack Overflow" },
];
