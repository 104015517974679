import React from "react";
import { PoseGroup } from "react-pose";
import PropTypes from "prop-types";

import Seperator from "components/atoms/Seperator";
import Navigation from "components/atoms/Navigation";

import { SSR } from "components/utils/SSR";
import { FontDelayer } from "components/utils/FontDelayer";

import GlobalStyles from "styles/global";
import { getPageFromPath } from "utils/common";

import { Header, Main } from "./styles";

const Layout = ({ children, ssr, location }) => {
  const path = getPageFromPath(location.pathname);
  return (
    <SSR ssr={!!ssr}>
      <FontDelayer>
        <GlobalStyles />

        <Header>
          <Navigation path={path} />
          <Seperator color="secondary" path={path} />
        </Header>

        <PoseGroup>
          <Main key={path} small={path === "home"}>
            {children}
          </Main>
        </PoseGroup>
      </FontDelayer>
    </SSR>
  );
};

Layout.propTypes = {
  ssr: PropTypes.bool,
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};

export default Layout;
