import React from "react";
import PropTypes from "prop-types";

import SSRAnimation from "components/atoms/SSRAnimation";
import variables from "styles/variables";

import { StyledText } from "./styles";

const Text = ({ children, inline, align, color, variant }) => (
  <StyledText inline={inline} align={align} color={color} variant={variant}>
    <SSRAnimation>{children}</SSRAnimation>
  </StyledText>
);

Text.propTypes = {
  children: PropTypes.node.isRequired,
  inline: PropTypes.bool,
  align: PropTypes.oneOf(["inherit", "left", "center", "right"]),
  color: PropTypes.oneOf(variables.colors.schema),
  variant: PropTypes.oneOf(["normal", "h1", "h2", "h3", "p"]),
};

Text.defaultProps = {
  align: "inherit",
  color: "inherit",
  variant: "normal",
};

export default Text;
