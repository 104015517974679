import React, { Component } from "react";
import PropTypes from "prop-types";

const WF_CLASS = "wf-active";

export const FontDelayerContext = React.createContext();

export class FontDelayer extends Component {
  state = { loaded: false };

  componentDidMount = () => {
    this.observer = new MutationObserver(this.callback);
    this.observer.observe(document.documentElement, { attributes: true });
  };

  callback = list => {
    for (var mutation of list) {
      if (mutation.attributeName === "class" && mutation.target.classList.contains(WF_CLASS) && !this.isLoaded) {
        this.isLoaded = true;
        this.setState({ loaded: true });
        this.observer.disconnect();
      }
    }
  };

  render() {
    const { children } = this.props;

    return <FontDelayerContext.Provider value={this.state}>{children}</FontDelayerContext.Provider>;
  }
}

FontDelayer.propTypes = {
  children: PropTypes.node.isRequired,
};
