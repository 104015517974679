export const slideIn = (axis, { enter = 0, exit = 100 } = {}) => ({
  enter: { [axis]: enter, opacity: 1 },
  exit: { [axis]: exit, opacity: 0 },
});

const skip = ["", "404", "404.html"];
export const getPageFromPath = pathname => {
  const path = pathname.replace(/\/+?/g, "");
  return skip.includes(path) ? "home" : path;
};
