import posed from "react-pose";
import styled from "styled-components";

const delayer = posed.div({
  visible: { opacity: 1 },
  invisible: { opacity: 0 },
});

export const Delayer = styled(delayer)`
  opacity: 0;
`;
