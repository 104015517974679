import React, { Fragment } from "react";
import PropTypes from "prop-types";

import Icon from "components/atoms/Icon";
import Toggle from "components/atoms/Toggle";
import { SSRContext } from "components/utils/SSR";
import { getPageFromPath } from "utils/common";

import { Main, Social } from "./items";
import { NOT_FOUND, ITEMS_MAIN, ITEMS_SOCIAL } from "./utils";
import { Nav, Container, Dropdown, Wrapper, MobileView, DesktopView } from "./styles";

const Navigation = ({ path }) => {
  const page = ITEMS_MAIN.find(({ link }) => getPageFromPath(link) === path) || NOT_FOUND;

  return (
    <Nav>
      <Container>
        <MobileView>
          <Main items={[page]} />

          <Toggle>
            {({ open, toggle }) => (
              <Fragment>
                <div>
                  <Wrapper>
                    <Icon onClick={toggle}>menu</Icon>
                  </Wrapper>
                </div>

                <SSRContext.Consumer>
                  {ssr =>
                    !ssr && (
                      <Dropdown pose={open ? "open" : "closed"}>
                        <Main items={ITEMS_MAIN} onClick={toggle} />
                      </Dropdown>
                    )
                  }
                </SSRContext.Consumer>
              </Fragment>
            )}
          </Toggle>
        </MobileView>

        <DesktopView>
          <div>
            <Main items={ITEMS_MAIN} />
          </div>

          <div>
            <Social items={ITEMS_SOCIAL} />
          </div>
        </DesktopView>
      </Container>
    </Nav>
  );
};

Navigation.propTypes = {
  path: PropTypes.string.isRequired,
};

export default Navigation;
