import { createGlobalStyle } from "styled-components";

import IconsWoff from "fonts/Icons.woff";
import IconsWoff2 from "fonts/Icons.woff2";

import variables from "styles/variables";

export default createGlobalStyle`
  @font-face {
    font-family: "Icons";
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: url(${IconsWoff2}) format("woff2"), url(${IconsWoff}) format("woff");
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html {
    color: ${variables.colors.font};
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    font-family: ${variables.fontFamily};
    font-weight: normal;
    font-size: 16px;
    line-height: 1;
  }

  body {
    margin: 0;
    padding: 0;
    min-width: 320px;
    overflow-x: hidden;
    background-color: ${variables.colors.body};
  }

  html, body, #___gatsby, #___gatsby > * {
    height: 100%;
  }

  a {
    color: inherit;
    display: inline-block;
    text-decoration: none;
    transition: color 0.5s;
    outline: none;
    cursor: pointer;

    &:hover,
    &.active {
      color: ${variables.colors.secondary};
    }
  }

  input, textarea {
    border: 0;
    outline: none;
    color: ${variables.colors.light};
    background-color: ${variables.colors.inputs};
    font-size: inherit;
    font-family: "Arial";
    line-height: inherit;

    width: 100%;
    margin: 0;
    padding: 10px 15px;

    &:invalid {
      box-shadow: none;
    }
  }

  ::-webkit-scrollbar-button {
    display: none;
  }
  ::-webkit-scrollbar-button:vertical:decrement {
    height: 55px;
    display: inline-block;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${variables.colors.space.in};
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    width: 15px;
  }
`;
