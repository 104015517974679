export default {
  fontFamily: "Raleway, sans-serif",
  header: 50,
  fullHeader: 53,
  borderRadius: 5,
  media: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  colors: {
    font: "#616161",
    dark: "#1b242f",
    light: "#ffffff",
    primary: "#04c2c9",
    secondary: "#e31b6d",
    space: {
      in: "#1b2735",
      out: "#090a0f",
    },

    body: "#252934",
    inputs: "#1e242c",

    schema: ["inherit", "primary", "secondary", "light"],
  },
};
