import React from "react";
import PropTypes from "prop-types";

export const SSRContext = React.createContext();

export const SSR = ({ children, ssr }) => <SSRContext.Provider value={ssr}>{children}</SSRContext.Provider>;

SSR.propTypes = {
  children: PropTypes.node.isRequired,
  ssr: PropTypes.bool.isRequired,
};
