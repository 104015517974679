import React from "react";
import PropTypes from "prop-types";

import { SSRContext } from "components/utils/SSR";

import { paths } from "./utils";
import { Svg, Path } from "./styles";

const Seperator = ({ path }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1000 500" preserveAspectRatio="none">
    <SSRContext.Consumer>{ssr => <Path pose={path} d={ssr ? paths[path].d : undefined} />}</SSRContext.Consumer>
  </Svg>
);

Seperator.propTypes = {
  path: PropTypes.oneOf(Object.keys(paths)).isRequired,
};

export default Seperator;
