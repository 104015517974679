import { tween } from "popmotion";
import { interpolate } from "flubber";

const transition = ({ from, to }) => tween({ from: 0, to: 1 }).pipe(interpolate(from, to));
export const paths = {
  home: { d: "M 0 0 L 1000 0 L 1000 20 L 0 20 Z", transition },
  about: { d: "M 0 0 L 1000 0 L 1000 20 C 700 0 650 500 0 500 Z", transition },
  blog: { d: "M 0 0 L 1000 0 L 1000 20 A 50 48 0 1 1 0 20 Z", transition },
  contact: { d: "M 0 0 L 1000 0 L 1000 20 C 0 660 1000 660 0 20 Z", transition },
};
